import {
  ButtonV3,
  CardButton,
  GridItem,
  TypographyV3,
  useGoToSwipeableSlide,
} from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useRef } from 'react';
import { type AriaDialogProps, useDialog } from 'react-aria';

import { EventType } from '@/analytics/types';
import BackdropBlur from '@/app/components/BackdropBlur';
import Image from '@/app/components/Image';

import { sliderContentOR4 } from './_data/membershipSliderContent';
import { OverlayButtons } from './HomeSliderNav';

export interface HomeOverlayContentProps extends AriaDialogProps {
  readonly openOverlayAtId: number;
  readonly onChange: (id: number) => void;
  readonly onClose: () => void;
}

const HomeOverlayContent = ({
  openOverlayAtId,
  onChange,
  onClose,
  ...props
}: HomeOverlayContentProps): JSX.Element | null => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { dialogProps, titleProps } = useDialog(props, wrapperRef);
  const { goToSlide } = useGoToSwipeableSlide();
  const t = useTranslations();
  const slides = sliderContentOR4();

  // close overlay on escape or enter key
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const { code } = event;
      if (code === 'Escape' || code === 'Enter') {
        goToSlide(openOverlayAtId);
        onClose();
      }
    },
    [goToSlide, onClose, openOverlayAtId],
  );

  useEffect(() => {
    if (!wrapperRef.current) return;
    const current = wrapperRef.current;
    current.addEventListener('keydown', handleKeyDown);

    return () => {
      current.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const slide = slides.find((slide) => slide.id === openOverlayAtId);

  if (!slide) return null;

  const Icon = slide.tag.icon;
  const tagLabel = t(slide.tag.label);

  const handleBack = () => {
    if (slide.id > 0) {
      onChange(slide.id - 1);
    } else if (slide.id === 0) {
      onChange(slides.length - 1);
    }
  };

  const handleForward = () => {
    if (slide.id < slides.length - 1) {
      onChange(slide.id + 1);
    } else if (slide.id === slides.length - 1) {
      onChange(0);
    }
  };

  const handleMouseDown = () => {
    goToSlide(openOverlayAtId);
    onClose();
  };

  const handleLearnMoreClick = async (): Promise<void> => {
    await window.ouraAnalytics.track(EventType.LinkClicked, {
      cta: 'learn more',
      location: 'home membership slider',
      path: slide.button.href,
    });
  };

  return (
    <div
      {...dialogProps}
      className="h-dvh w-full overflow-hidden bg-black"
      ref={wrapperRef}
    >
      <div
        className="grid h-full grid-cols-4 grid-rows-4 gap-4"
        data-cy="home-overlay-content"
      >
        <GridItem
          className="relative"
          colEnd={{ sm: 5 }}
          colStart={{ sm: 1 }}
          rowEnd={{ sm: 5 }}
          rowStart={{ sm: 1 }}
        >
          <Image
            alt={t(slide.image.mobile.overlay.alt)}
            className="object-cover"
            src={slide.image.mobile.overlay.src}
            fill
          />
        </GridItem>
        <GridItem
          className="pt-6 pl-6"
          colEnd={{ sm: 4 }}
          colStart={{ sm: 1 }}
          rowEnd={{ sm: 2 }}
          rowStart={{ sm: 1 }}
        >
          <div
            className={cx(
              'relative inline-flex items-center overflow-hidden px-4 py-3',
              'gap-x-2.5',
              'md:px-6 md:py-4',
            )}
          >
            <BackdropBlur variant="pill" />
            <Icon className="relative inline-block w-6 text-sandstone-200" />
            <span className="relative text-sandstone-200" {...titleProps}>
              {t(slide.tag.label)}
            </span>
          </div>
        </GridItem>
        <GridItem
          className="relative flex flex-col items-end pt-6 pr-6"
          colEnd={{ sm: 5 }}
          colStart={{ sm: 4 }}
          rowEnd={{ sm: 2 }}
          rowStart={{ sm: 1 }}
        >
          <CardButton
            ariaLabel={t('close_overlay_aria_label')}
            variant="expand"
            color="light"
            size="large"
            open
            onMouseDown={handleMouseDown}
          />
        </GridItem>
        <GridItem
          className="relative self-end px-6 pb-6"
          colEnd={{ sm: 5 }}
          colStart={{ sm: 1 }}
          rowEnd={{ sm: 5 }}
          rowStart={{ sm: 3 }}
        >
          <TypographyV3 Element="h6" variant="h4" color="light" height="none">
            {t.rich(slide.title)}
          </TypographyV3>
          <TypographyV3
            Element="p"
            color="light"
            height="snug"
            className="my-6"
          >
            {t(slide.description)}
          </TypographyV3>
          <div className="relative mb-10 p-4">
            <BackdropBlur variant="card" />
            <TypographyV3 Element="div" color="light" className="relative mb-4">
              {slide.quote.author}
            </TypographyV3>
            <TypographyV3 Element="div" color="light" className="relative mb-4">
              {t('member_spotlight')}
            </TypographyV3>
            <TypographyV3
              Element="div"
              color="light"
              font="serif"
              variant="body-large"
              className="relative"
            >
              {t(slide.quote.content)}
            </TypographyV3>
          </div>
          <div className="flex justify-between">
            <ButtonV3
              aria-label={t('learn_more_aria_label', { tag: tagLabel })}
              href={slide.button.href}
              variant="secondary-light"
              className="relative"
              onClick={handleLearnMoreClick}
            >
              {t(slide.button.label)}
            </ButtonV3>
            <OverlayButtons onBack={handleBack} onForward={handleForward} />
          </div>
        </GridItem>
      </div>
    </div>
  );
};

export default HomeOverlayContent;
