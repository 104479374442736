import { useDynamicConfig } from '@statsig/react-bindings';
import React from 'react';

import useSpringPriceDrop from '@/app/experiments/SpringPriceDrop/useSpringPriceDrop';

import DexcomHero from './_heros/DexcomHero';
import Gen3Holiday2025Hero from './_heros/Gen3Holiday2025Hero';
import Holiday2024Hero from './_heros/Holiday2024Hero';
import OR4Hero from './_heros/OR4Hero';
import SpringPriceDropHero from './_heros/SpringPriceDropHero';

const heroLookup: Record<string, () => React.JSX.Element> = {
  OR4Hero: () => <OR4Hero />,
  Holiday2024Hero: () => <Holiday2024Hero />,
  Gen3Holiday2025Hero: () => <Gen3Holiday2025Hero />,
  DexcomHero: () => <DexcomHero />,
  SpringPriceDropHero: () => <SpringPriceDropHero />,
};

type HeroType =
  | 'OR4Hero'
  | 'Holiday2024Hero'
  | 'BoxingDay2024Hero'
  | 'BlackFriday2024Hero'
  | 'Gen3Holiday2025Hero';

// If statsig misbehaves we will fallback to this
const FallbackHero = OR4Hero;

const HomeHeroGate = () => {
  const configHero = useDynamicConfig('home_hero').get(
    'hero',
    'OR4Hero',
  ) as HeroType;
  //TODO: GROW-2291 Clean up this experiment, and replace 'configHeroExperiment' with just 'configHero'
  const isSpringPriceDrop = useSpringPriceDrop();
  const configHeroExperiment = isSpringPriceDrop
    ? 'SpringPriceDropHero'
    : configHero;

  const Hero = heroLookup[configHeroExperiment];

  return Hero ? <Hero /> : <FallbackHero />;
};

export default HomeHeroGate;
