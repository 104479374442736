import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';

import { EventType } from '@/analytics/types';
import SectionHeading from '@/app/components/SectionHeading';

import HomeHero from '../HomeHero';
import HomeHeroShopChiclet from '../HomeHeroShopChiclet';
import HomeHeroWithChicletWrapper from '../HomeHeroWithChicletWrapper';
import HsaFsaIcon from './common/HsaFsaIcon';
import OR4HeroBackground from './common/OR4HeroBackground';

const Holiday2024Hero = (): JSX.Element => {
  const t = useTranslations();
  const path = usePathname();

  return (
    <HomeHeroWithChicletWrapper data-cy="holiday-24-hero">
      <HomeHero
        heroContent={{
          button: {
            children: null,
            href: '/product/rings',
            label: 'shop_oura_ring_4',
            onClick: () => {
              void window.ouraAnalytics.track(EventType.CTAClicked, {
                cta: 'shop',
                action: 'go_to_pdp',
                location: 'home hero',
                path,
              });
            },
          },
          copy: {
            value: t.rich('holiday_2024_hero_copy'),
          },
          icon: <HsaFsaIcon />,
        }}
        overlayPosition="absolute"
        TitleSlot={
          <div className="mt-24 max-lg:mx-auto md:mt-[27.5vh] md:max-w-[540px] lg:mt-[25vh]">
            <SectionHeading
              content="holiday_2024_hero_title"
              typographyProps={{
                className: 'font-normal leading-tighter max-lg:text-center',
                color: 'light',
                Element: 'h1',
                variant: 'h1',
              }}
            />
          </div>
        }
        TopSlot={<OR4HeroBackground />}
      />
      <HomeHeroShopChiclet />
    </HomeHeroWithChicletWrapper>
  );
};

export default Holiday2024Hero;
