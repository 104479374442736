import { useExperiment } from '@statsig/react-bindings';

type MultivariateLocation = 'none' | 'hero' | 'footer';

export function useMultivariatePayments() {
  const experiment = useExperiment('hp_multivariate_payments');
  const multivariateLocation = experiment.get(
    'multivariate_location',
    'none',
  ) as MultivariateLocation;

  return { multivariateLocation };
}
