import { cx } from 'class-variance-authority';

const MultivariatePill = ({
  children,
  classname,
}: {
  readonly children: JSX.Element;
  readonly classname?: string;
}): JSX.Element => {
  return (
    <div
      className={cx(
        'flex h-[34px] items-center justify-center rounded-sm bg-white px-2 py-2',
        classname,
      )}
      data-cy="multivariate-pill"
    >
      {children}
    </div>
  );
};

export default MultivariatePill;
