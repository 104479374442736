import { useDynamicConfig } from '@statsig/react-bindings';
import { cx } from 'class-variance-authority';
import { usePathname } from 'next/navigation';
import type { FocusEvent } from 'react';
import type { MessageKey } from 'types/LocaleMessageKey';

import { EventType } from '@/analytics/types';
import { type ImageFormat } from '@/app/components/Image';
import LandingPageHeroChiclet from '@/app/components/LandingPage/LandingPageHero/LandingPageHeroChiclet';

interface RenderData {
  additionalSegmentProperties: {
    cta: string;
    location: string;
    action: string;
  };
  ctaLocaleKey: MessageKey;
  image: {
    altKey: MessageKey;
    src: string;
    format: ImageFormat;
  };
  localeKey: MessageKey;
  target: string;
  rel: string;
  url: string;
}

interface StatsigHomeHeroShopChicletProps {
  readonly className?: string;
  readonly wrap?: 'md' | 'lg';
}

const StatsigHomeHeroShopChiclet = ({
  className,
  wrap = 'md',
}: StatsigHomeHeroShopChicletProps): JSX.Element | null => {
  const path = usePathname();
  const config = useDynamicConfig('home_hero_chiclet');
  const activeChicletConfig = Object.keys(config.value).length > 0;
  const value = config.value as unknown as RenderData;
  const renderData = activeChicletConfig && value;

  if (!renderData) return null;

  return (
    <HomeHeroShopChiclet
      cta={{
        href: renderData.url,
        label: renderData.ctaLocaleKey,
        rel: renderData.rel,
        target: renderData.target,
      }}
      className={className}
      image={{
        format: renderData.image.format,
        localizedAlt: renderData.image.altKey,
        src: renderData.image.src,
      }}
      segmentProperties={{
        cta: renderData.additionalSegmentProperties.cta,
        location: renderData.additionalSegmentProperties.location,
        action: renderData.additionalSegmentProperties.action,
        path,
      }}
      title={renderData.localeKey}
      wrap={wrap}
    />
  );
};

interface HomeHeroShopChicletProps {
  readonly title: MessageKey;
  readonly cta: {
    href: string;
    label: MessageKey;
    rel: string;
    target: string;
  };
  readonly image: {
    localizedAlt: MessageKey;
    src: string;
    format: ImageFormat;
  };
  readonly segmentProperties: {
    cta: string;
    location: string;
    action: string;
    path: string;
  };
  readonly onClick?: () => void;
  readonly className?: string | undefined;
  readonly wrap?: 'md' | 'lg';
}

const defaultHandleClick =
  ({
    cta,
    action,
    location,
    path,
  }: {
    cta: string;
    action: string;
    location: string;
    path: string;
  }) =>
  () => {
    void window.ouraAnalytics.track(EventType.LinkClicked, {
      cta,
      action,
      location,
      path,
    });
  };

const handleFocus = (event: FocusEvent) => {
  event.preventDefault();
  event.stopPropagation();
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const HomeHeroShopChiclet = ({
  title,
  cta,
  image,
  segmentProperties,
  onClick,
  className,
  wrap = 'md',
}: HomeHeroShopChicletProps) => {
  return (
    <div
      className={cx(
        'right-6 bottom-8 z-30 mt-6 flex justify-center px-5 lg:right-14 lg:bottom-14',
        {
          'md:absolute md:mt-0': wrap === 'md',
          'lg:absolute lg:mt-0': wrap === 'lg',
        },
      )}
      data-cy="home-hero-chiclet-wrapper"
    >
      <LandingPageHeroChiclet
        className={cx(className, 'mb-0!')}
        cta={cta}
        data-cy="home-hero-shop-chiclet"
        image={image}
        motionProps={{
          transition: { delay: 1, duration: 0.25 },
        }}
        onClick={onClick ?? defaultHandleClick(segmentProperties)}
        onFocusWithin={handleFocus}
        title={title}
      />
    </div>
  );
};

export default StatsigHomeHeroShopChiclet;
