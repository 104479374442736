import { GridItem, LayoutGrid, TypographyV3 } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';

import MultivariatePaymentOptions from '@/app/components/MultivariatePaymentOptions';
import { useMultivariatePayments } from '@/app/experiments/MultivariatePayments/useMultivariatePayments';

interface FooterMultivariatePaymentOptionsProps {
  readonly className?: string;
  readonly innerClassName?: string;
}

const FooterMultivariatePaymentOptions = ({
  className,
  innerClassName,
}: FooterMultivariatePaymentOptionsProps): JSX.Element => {
  const t = useTranslations();
  const { multivariateLocation } = useMultivariatePayments();

  if (multivariateLocation !== 'footer') {
    return <></>;
  }

  return (
    <section
      className={className}
      data-cy="footer-multivariate-payment-options"
    >
      <LayoutGrid>
        <GridItem colEnd={{ sm: 'main' }} colStart={{ sm: 'main' }}>
          <div
            className={cx(
              'flex h-20 w-full flex-row items-center justify-center gap-8 rounded-md bg-[#282826] px-4 py-4',
              innerClassName,
            )}
          >
            <TypographyV3 className="hidden text-center font-bold tracking-wide text-sandstone-200 lg:block">
              {t('flexible_payment_options')}
            </TypographyV3>
            <MultivariatePaymentOptions />
          </div>
        </GridItem>
      </LayoutGrid>
    </section>
  );
};

export default FooterMultivariatePaymentOptions;
