import { useDynamicConfig } from '@statsig/react-bindings';

interface AnimationConfig {
  cycleDuration: number;
  transitionDuration: number;
}

export function useMultivariateAnimationConfig(): AnimationConfig {
  const defaultAnimationConfig: AnimationConfig = {
    cycleDuration: 1750,
    transitionDuration: 0.6,
  };

  const dynamicAnimationConfig = useDynamicConfig(
    'multivariate_payment_animation_timing',
  ).value as unknown as AnimationConfig;

  if (
    !dynamicAnimationConfig.cycleDuration ||
    !dynamicAnimationConfig.transitionDuration
  ) {
    return defaultAnimationConfig;
  }

  return dynamicAnimationConfig;
}
