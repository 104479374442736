import React, { useEffect, useState } from 'react';

import Motion from '../Motion';

interface CyclingElementAnimatorProps {
  readonly cycleDuration?: number; // How long each element is displayed (milliseconds)
  readonly elements: React.ReactNode[]; // Array of elements to cycle through
  readonly transitionDuration?: number; // How long it takes for an element to fade in/out (seconds)
}

const CyclingElementAnimator: React.FC<CyclingElementAnimatorProps> = ({
  cycleDuration = 1500,
  elements,
  transitionDuration = 0.6,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [targetIndex, setTargetIndex] = useState(0);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const opacity = currentIndex === targetIndex ? 1 : 0;

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleAnimationComplete = () => {
    if (opacity) {
      // Clear any existing timeout to prevent overlapping timeouts
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Set the timeout to update targetIndex for the next cycle
      timeoutRef.current = setTimeout(() => {
        setTargetIndex((prevIndex) => (prevIndex + 1) % elements.length);
      }, cycleDuration);
    } else {
      // Update currentIndex after fading out the previous element
      setCurrentIndex(targetIndex);
    }
  };

  return (
    <Motion
      key={currentIndex}
      initial={{ opacity: 0 }}
      animate={{ opacity }}
      transition={{
        duration: transitionDuration,
        ease: 'easeIn',
      }}
      onAnimationComplete={handleAnimationComplete}
    >
      {elements[currentIndex]}
    </Motion>
  );
};

export default CyclingElementAnimator;
