import { GridItem, LayoutGrid, TypographyV3 } from '@jouzen/ecom-components';
import { useTranslations } from 'next-intl';
import type { ReactNode } from 'react';

import Image from '@/app/components/Image';
import Motion from '@/app/components/Motion';

interface MobileFeatureProps {
  readonly children: ReactNode;
  readonly title: string;
  readonly image: {
    alt: string;
    src: string;
  };
}

const MobileFeature = ({
  children,
  title,
  image,
}: MobileFeatureProps): JSX.Element => {
  const t = useTranslations();
  return (
    <Motion
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1, type: 'tween' }}
      className="relative flex flex-col justify-center gap-x-6 pt-12 pb-32 lg:flex-row lg:py-0"
      data-cy="mobile-feature"
    >
      <LayoutGrid className="pb-6 lg:hidden">
        <GridItem colEnd={{ sm: 'main' }} colStart={{ sm: 'main' }}>
          <Motion
            initial={{ filter: 'blur(5px)', opacity: 0 }}
            whileInView={{ filter: 'blur(0px)', opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.25 }}
          >
            <TypographyV3 Element="h4" variant="h4">
              {t(title)}
            </TypographyV3>
          </Motion>
        </GridItem>
      </LayoutGrid>
      <Motion
        className="relative h-[500px] lg:hidden"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 1, type: 'tween', delay: 0.5 }}
      >
        <Image
          alt={t(image.alt)}
          src={image.src}
          fill
          sizes="100vw"
          className="object-cover"
        />
      </Motion>
      {children}
    </Motion>
  );
};

export default MobileFeature;
