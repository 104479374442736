import { useExperiment } from '@statsig/react-bindings';

//TODO: GROW-2291 Clean up this experiment
const useSpringPriceDrop = () => {
  return useExperiment('hp_spring_price_drop_hero').get(
    'spring_price_drop',
    false,
  );
};

export default useSpringPriceDrop;
