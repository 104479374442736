import { Tabs } from '@jouzen/ecom-components';
import { useTranslations } from 'next-intl';
import type { MessageKey } from 'types/LocaleMessageKey';

import HostingParty from './HostingParty';
import StartDay from './StartDay';
import TakeAWalk from './TakeAWalk';
import UnderWeather from './UnderWeather';
import WindDown from './WindDown';

interface TabsData {
  readonly tabs: { readonly label: MessageKey }[];
  readonly tabPanels: { readonly content: JSX.Element }[];
}

const tabsData: TabsData = {
  tabs: [
    {
      label: 'features_tab_1_label',
    },
    {
      label: 'features_tab_2_label',
    },
    {
      label: 'features_tab_3_label',
    },
    {
      label: 'features_tab_4_label',
    },
    {
      label: 'features_tab_5_label',
    },
  ],
  tabPanels: [
    {
      content: <StartDay />,
    },
    {
      content: <TakeAWalk />,
    },
    {
      content: <UnderWeather />,
    },
    {
      content: <WindDown />,
    },
    {
      content: <HostingParty />,
    },
  ],
};

interface HomeFeaturesTabsProps {
  readonly onTabChange: (index: number) => void;
}

export const HomeFeaturesTabs = ({
  onTabChange,
}: HomeFeaturesTabsProps): JSX.Element => {
  const t = useTranslations();

  const tabs = tabsData.tabs.map(({ label }) => ({
    className: 'px-0',
    label: t(label),
  }));

  return (
    <Tabs
      tabPanels={tabsData.tabPanels}
      tabs={tabs}
      onChange={onTabChange}
      tabList={{ className: 'justify-between!' }}
    />
  );
};
