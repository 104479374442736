//TODO: GROW-2166 when Productizing if fixed win update the InverseHeader in core component and fix imports
import { OuraRing4Logo, TypographyV3 } from '@jouzen/ecom-components';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';
import type { ReactNode } from 'react';

import { EventType } from '@/analytics/types';
import InverseHeader from '@/app/components/Experiments/FixedHeader/InverseHeader/InverseHeader';
import Motion from '@/app/components/Motion';

import HomeHero from '../HomeHero';
import HomeHeroShopChiclet from '../HomeHeroShopChiclet';
import HomeHeroWithChicletWrapper from '../HomeHeroWithChicletWrapper';
import HsaFsaIcon from './common/HsaFsaIcon';
import OR4HeroBackground from './common/OR4HeroBackground';

const gen4RichText = {
  em: function em(chunks: ReactNode) {
    return (
      <Motion
        className="mt-2 font-serif leading-tight italic md:mt-4"
        el="em"
        initial={{
          opacity: 0,
          translateY: 25,
          filter: 'blur(5px)',
          background: 'inherit',
        }}
        animate={{ opacity: 1, translateY: 0, filter: 'none' }}
        transition={{ duration: 2, type: 'tween', delay: 1.25 }}
      >
        {chunks}
      </Motion>
    );
  },
};

const OR4Hero = (): JSX.Element => {
  const t = useTranslations();
  const path = usePathname();

  return (
    <HomeHeroWithChicletWrapper data-cy="hero-gift-card-chiclet-wrapper">
      <InverseHeader>
        <div data-cy="or4-hero">
          <HomeHero
            heroContent={{
              button: {
                children: null,
                className: 'bg-slate-900 hover:bg-sandstone-500 font-bold',
                label: 'gen4_hero_cta',
                href: '/product/rings',
                onClick: () => {
                  void window.ouraAnalytics.track(EventType.CTAClicked, {
                    cta: 'shop_now',
                    action: 'go_to_pdp',
                    location: 'home hero',
                    path,
                  });
                },
                variant: 'secondary-dark',
              },
              icon: <HsaFsaIcon />,
            }}
            overlayPosition="absolute"
            TopSlot={<OR4HeroBackground />}
            TitleSlot={
              <div className="mt-40 md:mt-[27.5vh] md:max-w-[540px] lg:max-w-[46%]">
                <span className="mb-2 flex flex-row items-center justify-center md:mb-5 md:justify-start">
                  <OuraRing4Logo />
                </span>
                <TypographyV3
                  Element="h1"
                  variant="h1"
                  className="flex flex-col items-center bg-linear-to-br from-slate-900 to-mustard-500 bg-clip-text leading-none font-medium text-transparent sm:text-4xl md:items-start md:text-6xl lg:text-[6.667vw] xxl:text-8xl lg:de:text-[5vw] xxl:de:text-7xl"
                  color="light"
                >
                  {t.rich('sleeker_smarter_ring_title', gen4RichText)}
                </TypographyV3>
              </div>
            }
          />
        </div>
      </InverseHeader>
      <HomeHeroShopChiclet />
    </HomeHeroWithChicletWrapper>
  );
};

export default OR4Hero;
