import {
  LeftArrowIcon,
  RightArrowIcon,
  SwipeableNextButton,
  SwipeablePrevButton,
  useSwipeable,
} from '@jouzen/ecom-components';
import type { VariantProps } from 'class-variance-authority';
import { cva, cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import type { ComponentPropsWithoutRef } from 'react';

export type ArrowBtnVarType = VariantProps<typeof arrowBtnVar>;

const arrowBtnVar = cva('rounded-full px-3 py-4', {
  variants: {
    color: {
      default: [],
      gray: [],
      sandstone: ['bg-sandstone-400/10'],
      sandstoneLight: ['bg-sandstone-200/20', 'text-sandstone-500'],
    },
    disabled: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      className: 'bg-sandstone-200 text-gray-400',
      color: 'default',
      disabled: true,
    },
    {
      className: 'bg-sandstone-500 text-sandstone-200',
      color: 'default',
      disabled: false,
    },
    {
      className: 'bg-gray-600 text-gray-400',
      color: 'gray',
      disabled: true,
    },
    {
      className: 'bg-gray-500 text-sandstone-200',
      color: 'gray',
      disabled: false,
    },
    {
      className: 'text-gray-400',
      color: 'sandstone',
      disabled: true,
    },
    {
      className: 'text-sandstone-200',
      color: 'sandstone',
      disabled: false,
    },
    {
      className: 'opacity-20',
      color: 'sandstoneLight',
      disabled: true,
    },
  ],
  defaultVariants: {
    color: 'default',
    disabled: false,
  },
});

export const LeftArrowBtn = ({
  className,
  color,
  disabled,
  onClick,
}: ComponentPropsWithoutRef<'button'> & ArrowBtnVarType): JSX.Element => {
  const t = useTranslations();

  return (
    <SwipeablePrevButton
      aria-label={t('previous_slide_aria_label')}
      className={arrowBtnVar({ color, disabled, className })}
      data-cy="left-nav-btn"
      onClick={(evt) => onClick?.(evt)}
    >
      <LeftArrowIcon />
    </SwipeablePrevButton>
  );
};

export const RightArrowBtn = ({
  className,
  color,
  disabled,
  onClick,
}: ComponentPropsWithoutRef<'button'> & ArrowBtnVarType): JSX.Element => {
  const t = useTranslations();

  return (
    <SwipeableNextButton
      aria-label={t('next_slide_aria_label')}
      id="right-nav-btn"
      className={arrowBtnVar({ color, disabled, className })}
      data-cy="right-nav-btn"
      onClick={(evt) => onClick?.(evt)}
    >
      <RightArrowIcon />
    </SwipeableNextButton>
  );
};

interface SwipeableNavButtonsProps
  extends Omit<ComponentPropsWithoutRef<'button'>, 'color' | 'disabled'>,
    ArrowBtnVarType {
  readonly buttonClassName?: string;
}

const SwipeableNavButtons = ({
  buttonClassName,
  className,
  color,
  onClick,
}: SwipeableNavButtonsProps): JSX.Element => {
  const { canScrollNext, canScrollPrev } = useSwipeable();
  const _color = color ?? 'default';

  return (
    <div
      className={cx('flex justify-end gap-x-4 pt-6 pb-4', className)}
      data-cy="swipeable-nav-buttons"
    >
      <LeftArrowBtn
        className={buttonClassName}
        color={_color}
        disabled={!canScrollPrev}
        onClick={onClick}
      />
      <RightArrowBtn
        className={buttonClassName}
        color={_color}
        disabled={!canScrollNext}
        onClick={onClick}
      />
    </div>
  );
};

export default SwipeableNavButtons;
