import {
  AffirmLogo,
  AmericanExpressLogo,
  ApplePayLogo,
  GooglePayLogo,
  HsaFsaBlockLogo,
  MasterCardLogo,
  PaypalLogo,
  TypographyV3,
  VisaLogo,
} from '@jouzen/ecom-components';
import { useFeatureGate } from '@statsig/react-bindings';
import { useTranslations } from 'next-intl';
import type { MessageKey } from 'types/LocaleMessageKey';

import HsaFsaIcon from '@/app/[locale]/_components/HomeHero/_heros/common/HsaFsaIcon';
import { useMultivariateAnimationConfig } from '@/app/experiments/MultivariatePayments/useMultivariateAnimationConfig';

import CyclingElementAnimator from '../CyclingElementAnimator';
import MultivariatePill from './MultivariatePill';

interface PillsWithTitleProps {
  readonly children: React.ReactNode;
  readonly title: MessageKey;
}

const PillsWithTitle = ({ children, title }: PillsWithTitleProps) => {
  const t = useTranslations();
  return (
    <div className="flex w-full flex-row items-center justify-center gap-2">
      {children}
      <TypographyV3 className="font-normal text-sandstone-200">
        {t(title)}
      </TypographyV3>
    </div>
  );
};

const MultivariatePaymentOptions = (): JSX.Element => {
  const isUS = useFeatureGate('usa_only').value;

  const { cycleDuration, transitionDuration } =
    useMultivariateAnimationConfig();

  return (
    <>
      {/* Mobile */}
      <div className="flex h-12 items-center justify-center text-sandstone-200 lg:hidden">
        <CyclingElementAnimator
          cycleDuration={cycleDuration}
          transitionDuration={transitionDuration}
          elements={[
            ...(isUS
              ? [
                  <HsaFsaIcon key="mvp-mobile-hsa-fsa" />,
                  <PillsWithTitle
                    key="mvp-mobile-affirm"
                    title="flexible_financing"
                  >
                    <MultivariatePill classname="w-14">
                      <AffirmLogo className="h-4" />
                    </MultivariatePill>
                  </PillsWithTitle>,
                ]
              : []),
            <PillsWithTitle
              key="mvp-mobile-instant-checkout"
              title="instant_checkout"
            >
              <MultivariatePill>
                <PaypalLogo className="h-8" />
              </MultivariatePill>
              <MultivariatePill classname="border-2 border-black">
                <ApplePayLogo className="h-[18px]" />
              </MultivariatePill>
              <MultivariatePill classname="border-2 border-[#34383b] rounded-full!">
                <GooglePayLogo className="h-5" />
              </MultivariatePill>
            </PillsWithTitle>,
            <PillsWithTitle
              key="mvp-mobile-major-cards-accepted"
              title="major_cards_accepted"
            >
              <MultivariatePill>
                <VisaLogo className="h-10 w-10 text-[#172b85]" />
              </MultivariatePill>
              <MultivariatePill>
                <MasterCardLogo className="h-6" />
              </MultivariatePill>
              <MultivariatePill classname="bg-[#1F72CD]! px-1!">
                <AmericanExpressLogo className="h-12 w-12 text-white" />
              </MultivariatePill>
            </PillsWithTitle>,
          ]}
        />
      </div>

      {/* Desktop */}
      <div
        className="hidden flex-row items-center gap-2 text-black lg:flex"
        data-cy="multivariate-payment-options"
      >
        {isUS && (
          <>
            <MultivariatePill>
              <HsaFsaBlockLogo className="h-10" />
            </MultivariatePill>
            <MultivariatePill>
              <AffirmLogo className="h-4" />
            </MultivariatePill>
          </>
        )}
        <MultivariatePill>
          <PaypalLogo className="h-8" />
        </MultivariatePill>
        <MultivariatePill classname="border-2 border-black">
          <ApplePayLogo className="h-[18px]" />
        </MultivariatePill>
        <MultivariatePill classname="border-2 border-[#34383b] rounded-full!">
          <GooglePayLogo className="h-5" />
        </MultivariatePill>
        <MultivariatePill>
          <VisaLogo className="h-10 w-10 text-[#172b85]" />
        </MultivariatePill>
        <MultivariatePill>
          <MasterCardLogo className="h-6" />
        </MultivariatePill>
        <MultivariatePill classname="bg-[#1F72CD]! px-1!">
          <AmericanExpressLogo className="h-12 w-12 text-white" />
        </MultivariatePill>
      </div>
    </>
  );
};

export default MultivariatePaymentOptions;
